<template>
    <div>
        <div class="flex title" style="justify-content: space-between;">
            <Title title="稿件预览" />
            <div class="flex but" style="justify-content: space-between;">
                <!-- <button class="back" @click="$router.back()">返回</button> -->
                <button style="margin-right: 1rem;" class="copy" @click="copyTitle">复制标题</button>
                <button class="copy" @click="copy">复制内容</button>

            </div>
        </div>
        <div class="Man_content">
            <div class="Man_content_title_box">
                <h4 class="title">{{ DetailsContent.title }}</h4>
                <p class="hint">提示：本平台所发布信息的内容和准确性由提供消息的原单位或组织独立承担完全责任!</p>
                <p class="fillFormTime" v-show="DetailsContent.ltime">{{ DetailsContent.ltime ?
                    `限时稿件-截稿时间：${DetailsContent.ltime}` : '' }}</p>
            </div>
            <p class="Man_content_title" v-show="DetailsContent.url" @click="$goLink(DetailsContent.url)">转载链接: <span>{{
                    DetailsContent.url }}</span>
            </p>
            <p class="Man_content_title flex" v-show="keywordList.length != 0">敏感词: <i
                    v-for="(item, index) in keywordList" :key="index">{{
                    index == keywordList.length - 1 ? item : `${item},` }}</i>
            </p>
            <div class="remark_box" v-show="DetailsContent.remark">
                备注信息：{{ DetailsContent.remark }}
            </div>

            <div class="cover_image flex" v-show="DetailsContent.cover_image">
                <p class="cover_image_title">封面展示：</p>
                <img :src="DetailsContent.cover_image" alt="">
            </div>
            <div class="cover_image flex" v-show="DetailsContent.video_url">
                <p class="cover_image_title">视频预览：</p>
                <video :src="DetailsContent.video_url" controls="controls"></video>
            </div>
            <div v-show="DetailsContent.content" class="content" v-html="DetailsContent.content"></div>
        </div>

    </div>
</template>

<script>
import Title from '../../components/UserInfoTitle.vue'
export default {
    created() {
        this.get_list()
    },
    mounted() {

    },
    components: {
        Title,
    },
    data() {
        return {
            DetailsContent: {},
            // verifyArray: [],
            keywordList: []
        }
    },
    // 找出敏感字-标红
    // filters: {
    //     formatTitle(value, keyword) {
    //         if (!value) return '';
    //         // value = value.length > 30 ? value.substr(0, 29) + '...' : value; //标题超过30字符展示...
    //         if (Array.isArray(keyword)) {
    //             keyword.forEach((item) => {
    //                 if (item && value.includes(item)) {
    //                     var regex = RegExp('(' + item.replace('\\$1') + ')', 'ig');
    //                     var html = '<span style="color:red">' + item + '</span>';
    //                     value = value.replace(regex, html)
    //                     console.log(value);
    //                 }
    //             })
    //         }
    //         return value;
    //     },
    // },
    methods: {
        // 复制标题
        copyTitle() {
            this.$copyText(this.DetailsContent.title).then(
                (e) => {
                    return this.$message({
                        message: '复制标题成功',
                        type: 'success',
                    })
                },
                (err) => {
                    return this.$message({
                        message: '复制失败',
                        type: 'warning',
                    })
                }
            )
        },
        copy() {
            const text = document.querySelector(".content");
            let range = document.createRange();
            range.selectNodeContents(text);
            let selection = document.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand("Copy");
            // this.$toast("复制成功");
            return this.$message({
                message: '复制内容成功',
                type: 'success',
            })
        },
        get_list() {
            this.curlGet('/api/users/order/detail', {
                id: this.$route.query.id
            }).then(res => {
                if (res.data.code) {
                    this.DetailsContent = res.data.data[0]
                    // if (this.DetailsContent.limit_time > 0) {
                    //     this.DetailsContent.limit_time = timeCut(this.DetailsContent.limit_time)
                    // }
                    // this.content = toText(this.DetailsContent.content)
                    this.verifyTest()
                }
            })
        },
        verifyTest() {
            if (this.DetailsContent.content) {
                this.curlGet('/api/index/website').then(res => {
                    res.data.data.sensitive_word.forEach((item, index) => {
                        if (this.DetailsContent.content.indexOf(item) > 0) {
                            this.keywordList.push(item)
                        }
                    })
                })
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.cover_image {
    margin: 1rem 0;

    .cover_image_title {
        color: #333;
        font-size: 1.22rem;
        margin-right: 1rem;
    }

    img {
        width: 300px;
    }

    video {
        width: 300px;
    }
}

.title {
    .but {
        // width: 22rem !important;

        .back {
            width: 5.83rem;
            height: 2.5rem;
            background: #fff;
            color: #108CDD;
            border: 0;
            box-shadow: 0.17rem 0.17rem 0.33rem #000000;
            border-radius: 0.42rem;
            cursor: pointer;
        }

        .copy {
            width: 5.83rem;
            height: 2.8rem;
            border: 0;
            background: #108CDD;
            color: #fff;
            border: 0;
            border-radius: 0.42rem;
        }
    }
}

.Man_content {
    min-height: 100vh;
    padding: 24px 15%;
    box-sizing: border-box;
    position: relative;
    z-index: 999;
    border-radius: 0.83rem;
    background: #ffffff;
    box-shadow: 0.17rem 0.17rem 0.33rem #7a7a7a;
    margin-top: 1rem;
    margin-bottom: 10rem;

    .Man_content_title_box {
        border-bottom: 0.08rem solid #aaaaaa;
        padding-bottom: 1rem;
        // margin-bottom: 1rem;
        box-sizing: border-box;

        .title {
            color: #333;
            font-size: 1.8rem;
            text-align: center;

        }
    }

    .Man_content_title {
        background: #E1F1FC;
        padding: 1rem 0;
        box-sizing: border-box;
        color: #108CDD;
        font-size: 1.22rem;

        span {
            cursor: pointer;
            color: #333;
            font-size: 1.22rem;
            text-decoration: underline;
            padding-left: .5rem;
        }

        i {
            font-style: normal;
            color: rgba(255, 0, 0, 1);
            font-size: 1.22rem;

            &:first-child {
                margin-left: .5rem;
            }
        }
    }

    .content {
        padding-top: 2rem;
        box-sizing: border-box;
        font-size: 1.4rem;
        line-height: 3rem;

        ::v-deep p {
            // font-size: 1.22rem !important;
            color: #333;
        }
    }
}

.fillFormTime {
    color: #EF2626;
    font-size: 1.33rem;
    font-weight: bold;
    text-align: left;
}

.hint {
    color: #EF2626;
    font-size: 1.2rem;
    text-align: center;
}

.remark_box {
    background: #E1F1FC;
    padding: 1rem 0;
    max-height: 10rem;
    overflow-y: auto;
    font-size: 1.22rem;
    box-sizing: border-box;
}
</style>